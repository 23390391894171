import { LoggedUserDetailPojo } from './logged-user-details.pojo';
import { SettingsPojo } from './settings.pojo';

export class DevoteeUserMasterPojo {

    userId: number;
    userName: string;
    password: string;

    name: string;
    mobileNo: string;
    emailId: string;
    address: string;
    communicationAddress: string;
    city: string;
    state: string;
    pincode: string;
    country: string;
    nriFlag: string;
    proofType: string;
    proofNumber: string;
    dob: Date;
    age: number;
    gender: string;
    imageId: number;
    fileName: string;
    fileAddressPath: string;
    status: string;

    registerDate: Date;

    loggedUserDetails = new LoggedUserDetailPojo();
    settings = new SettingsPojo();

}
