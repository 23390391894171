import { Component, OnInit } from '@angular/core';
import { StaticVariable } from 'src/app/globle.class';
import { TempleHeaderImage } from 'src/app/Extras/header-image';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
   TempleName: any;
    TempleGodImage: any;
    districtName: string;
    pincode: string;
  constructor() { }

  ngOnInit() {
      this.TempleName = StaticVariable.TempleName;
      this.TempleGodImage = TempleHeaderImage.TempleAmmanImage;
      this.districtName = StaticVariable.District;
      this.pincode = StaticVariable.Pincode;
  }

}
