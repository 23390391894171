import { ReceiptHeaderImage } from './Extras/receipt-header-image';

export class StaticVariable {

    // static porturl = 'http://localhost:8083/TicketBookingApi/';

    // static porturl = 'http://192.168.43.214:8083/TicketBookingApi/';

   //  static porturl = 'http://192.168.0.10:8083/TicketBookingApi/';
    static porturl = 'http://148.66.133.79:8080/coralBeachResortTicketBookingApi/';
    static LS_TempleDetails = "templeDetails";
    
    //  static porturl = 'http://localhost:8082/TicketBookingApi/';

    static TempleName = 'DHARMAPURAM ADHEENAM';
    static District = 'Dharmapuram';
    static Pincode = '621112';
    static Email = '';
    static receiptBanner = ReceiptHeaderImage.receiptBanner;
    static status_1 = 'INITIAL';
    static status_2 = 'SUCCESS';
    static status_3 = 'FAILED';
    static status_4 = 'PENDING';
    static smsEncryptOffset = 25;
    static technicalSupport = '';
    static mail1 = '';
    static mail2 = '';
    static landLine = '04142 287 515';
    static tollFree = '';
    static pgKey1 = 'SxwgqL';
    static pgKey2 = '';
    static pgSalt1 = 'zC7VUYYY';
    static pgSalt2 = '';

}


