import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DevoteeUserMasterService } from './Services/devotee-user-master.service';
import { DevoteeUserMasterPojo } from './Pojos/devotee-user-master.pojo';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

    loadingFlag: boolean;

    loggedUser = new DevoteeUserMasterPojo();

    constructor(private router: Router, private devoteeUserMasterService: DevoteeUserMasterService) {


        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loadingFlag = true;
                    break;
                }
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loadingFlag = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });

    }

    ngOnInit() {
        // if (sessionStorage.getItem('CurrentSession') != 'true') {
        //     sessionStorage.setItem('CurrentSession', 'true');
        //     sessionStorage.setItem('ETicketUserDetail', JSON.stringify(this.loggedUser));
        //     this.getDevoteeUserDetailsByDevoteeUserId();
        // }
    }

    // getDevoteeUserDetailsByDevoteeUserId() {
    //     this.devoteeUserMasterService.devoteeUserMasterSearchById(1).subscribe(response => {
    //         this.loggedUser = response;
    //         sessionStorage.setItem('ETicketUserDetail', JSON.stringify(this.loggedUser));
    //     }, error => {
    //     });
    // }

    onActive() {
        window.scroll(0, 0);
    }

}
