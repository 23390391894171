import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DataTableModule } from "angular-6-datatable-cc";

import { NgbModule, NgbDatepickerKeyboardService, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { AstPaginator } from '../Utils/paginator';
import { CustomKeyboardService } from '../Utils/custom-keyboard.service';
import { CustomDateFormatter } from '../Utils/custom-date-formatter';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
    declarations: [
        LoadingComponent,
        HeaderComponent,
        FooterComponent,
        AstPaginator,
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        DataTableModule,
        NgbModule,
        RecaptchaModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        AstPaginator,
        FormsModule,
        HttpClientModule,
        DataTableModule,
        NgbModule,
        LoadingComponent,
        RecaptchaModule
    ],
    providers: [
        { provide: NgbDatepickerKeyboardService, useClass: CustomKeyboardService },
        { provide: NgbDateParserFormatter, useClass: CustomDateFormatter }
    ]
})

export class SharedModule { }
