import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { StaticVariable } from '../globle.class';
import { DevoteeUserMasterPojo } from '../Pojos/devotee-user-master.pojo';
import { OtpMasterPojo } from '../Pojos/otp-master.pojo';
import { ResponsePojo } from '../Pojos/response.pojo';


@Injectable({
    providedIn: 'root'
})

export class DevoteeUserMasterService {

    constructor(private httpClient: HttpClient) { }

    addDevoteeUserMaster(arg: DevoteeUserMasterPojo, otpMaster: OtpMasterPojo): Observable<ResponsePojo> {
        let params = new HttpParams().set('otpid', otpMaster.otpId + '').set('otp', otpMaster.otp);
        const url = StaticVariable.porturl + 'AddDevoteeUserMaster';
        return this.httpClient.post<ResponsePojo>(url, arg, { params: params });
    }

    updateDevoteeUserMaster(arg: DevoteeUserMasterPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'DevoteeUserMasterUpdate';
        return this.httpClient.put<ResponsePojo>(url, arg);
    }

    devoteeUserMasterSearchById(devoteeUserId: number): Observable<DevoteeUserMasterPojo> {
        const url = StaticVariable.porturl + 'DevoteeUserMasterSearchById/' + devoteeUserId;
        return this.httpClient.get<DevoteeUserMasterPojo>(url);
    }

}
