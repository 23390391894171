export class LoggedUserDetailPojo {

    loggedUserId: number;
	transactionFlag: string;
	transactionDateTime: Date;
	transactionIp: string;
	name: string;
    userName: string;

}
