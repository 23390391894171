import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from './app.guard';

const routes: Routes = [
//{ path: '', component: UserLoginComponent },
    // { path: '', loadChildren: './user/user-landing-page/user-landing-page.module#UserLandingPageModule',  },
    { path: '', loadChildren: './RoomBooking/eroom-booking/eroom-booking.module#ERoomBookingModule' },
 //   { path: 'user', loadChildren: './user/user-landing-page/user-landing-page.module#UserLandingPageModule',  },
 //   { path: 'receiptdownload', loadChildren: './receipt/receipt.module#ReceiptModule' },
  ];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
