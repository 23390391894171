import { Component, Input, OnChanges } from "@angular/core";
import { DataTable } from "angular-6-datatable-cc/src/DataTable";

import * as _ from "lodash";

@Component({
    selector: "ast-paginator",
    templateUrl: './../paginator.html',
    styleUrls: ['./../shared/shared.scss']
})

export class AstPaginator implements OnChanges {

    @Input("rowsOnPageSet") rowsOnPageSet = [];
    @Input("mfTable") mfTable: DataTable;

    minRowsOnPage = 0;

    ngOnChanges(changes: any): any {
        if (changes.rowsOnPageSet) {
            this.minRowsOnPage = _.min(this.rowsOnPageSet)
        }
    }
}
